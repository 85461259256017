<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card title="Manage Request">
        <!-- search input -->
        <div class="d-flex align-items-center">
          <b-form-group
            class="form-inline w-100"
            label="Search"
            label-size="sm"
          >

            <div>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search requests"
                type="text"
              />
              <b-button
                v-b-modal.addRequestModal
                variant="primary"
              >
                <feather-icon icon="PlusIcon" />
                Add Request
              </b-button>
              <b-form-checkbox
                v-model="hideClosed"
                class="d-flex align-items-center mt-1 justify-content-start"
              >
                Hide Closed Requests
              </b-form-checkbox>
            </div>
          </b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="requester"
            :options="[
              { text: 'All', value: null },
              { text: 'Incubator', value: 'Incubator' },
              { text: 'Mentor', value: 'Mentor' }
            ]"
            button-variant="light"
            buttons
            class="ml-auto"
            label="Show requests raised by"
            size="sm"
          />
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="filteredRows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <span
              v-if="props.column.field === 'title'"
              class="d-flex justify-content-between align-items-center"
            >
              {{ props.row.title }}
              <b-button
                variant="link"
                size="sm"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
              >View</b-button>
            </span>
            <span v-else-if="props.column.field === 'priority'">
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field])"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
            </span>
            <span
              v-else-if="props.column.id === 'assigned_to'"
              class="d-flex justify-content-between align-items-center"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-else-if="props.column.field === 'assignee'"
              class="d-flex justify-content-between align-items-center"
            >
              <span v-if="props.row.raisedbypartner">{{ selectedOrg.title }}</span>
              <span v-else-if="props.row.raisedbymentor">{{ userDetails.fullName }}</span>
              <span v-else>{{ props.row.users_organizationtable.title }}</span>
            </span>
            <span
              v-else-if="props.column.field === 'assignee_status'"
              class="d-flex justify-content-between align-items-center"
            >
              <b-badge
                class="text-capitalize"
                :variant="statusVariant(`${props.formattedRow[props.column.field]}`.toLowerCase())"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
              <b-dropdown
                v-if="!props.row.raisedbymentor"
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >

                <template #button-content>
                  <span class="align-middle text-nowrap">Update Status <feather-icon
                    class="align-middle ml-25"
                    icon="ChevronDownIcon"
                  /></span>
                </template>
                <b-dropdown-item
                  :disabled="props.row.status === 'in-process'"
                  variant="primary"
                  @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'in-process')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="ClockIcon"
                  />
                  <span>In-Process</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'resolved'"
                  variant="success"
                  @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'resolved')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckIcon"
                  />
                  <span>Resolved</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'closed'"
                  variant="danger"
                  @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'closed')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="XIcon"
                  />
                  <span>Closed</span>
                </b-dropdown-item>

              </b-dropdown>
            </span>
            <span
              v-else-if="props.column.field === 'status'"
              class="d-flex justify-content-between align-items-center"
            >
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field].toLowerCase())"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
              <b-dropdown
                v-if="props.row.raisedbymentor"
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
                no-caret
              >

                <template #button-content>
                  <span class="align-middle text-nowrap">Update Status <feather-icon
                    class="align-middle ml-25"
                    icon="ChevronDownIcon"
                  /></span>
                </template>
                <b-dropdown-item
                  variant="primary"
                  :disabled="props.row.status === 'in-process'"
                  @click="updateRequestStatus(props.row.requesttable_id, 'in-process')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="ClockIcon"
                  />
                  <span>In-Process</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="success"
                  :disabled="props.row.status === 'resolved'"
                  @click="updateRequestStatus(props.row.requesttable_id, 'resolved')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckIcon"
                  />
                  <span>Resolved</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  :disabled="props.row.status === 'closed'"
                  @click="updateRequestStatus(props.row.requesttable_id, 'closed')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="XIcon"
                  />
                  <span>Closed</span>
                </b-dropdown-item>

              </b-dropdown>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-modal
        id="addRequestModal"
        no-close-on-backdrop
        :ok-disabled="!(request.title && request.description && request.priority )"
        size="lg"
        title="Add Request"
        ok-title="Add"
        @ok="addRequest"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Request Title"
              label-for="Request Title"
            >
              <b-form-input
                id="requestTitle"
                v-model="request.title"
                placeholder="Request Title"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-textarea
                id="requestTitle"
                v-model="request.description"
                placeholder="Description"
                type="text"
                rows="2"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Priority level"
              label-for="Priority level"
            >
              <v-select
                v-model="request.priority"
                class="text-capitalize"
                :options="['low','medium','high','urgent']"
                placeholder="Choose Priority level"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        title="Request Details"
      >
        <div>
          <p>
            {{ requests[selectedRow].description }}
          </p>
          <hr>
          <div
            v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
          >
            <span
              class="font-medium-1"
            >Comments</span>
            <div
              v-for="(comment, index) in requests[selectedRow].comments"
              :key="index"
              class="border-top mt-1"
            >
              <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                {{ comment.created_by }}
                <span><feather-icon icon="CalendarIcon" />
                  {{ comment.created_at }}</span>
              </p>
              <p class="pl-50">
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BCard,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormRadioGroup,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 25,
      tab: 1,
      columns: [
        {
          label: 'Request Title',
          field: 'title',
        },
        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          id: 'assignee',
          label: 'Assignee',
          field: 'assignee',
        },
        {
          label: 'Assignee Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field(row) {
            return (row.created_at)
          },
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
        },
      ],
      hideClosed: false,
      requester: null,
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
        incubator: null,
      },
      assignee: null,
    }
  },
  computed: {
    ...mapGetters({
      userDetails: 'user/getUserDetails',
      selectedOrg: 'user/getSelectedOrgDetails',
      timeZone: 'user/getUserTimezone',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let { requests } = this
      if (this.requester) {
        requests = requests.filter(r => {
          if (this.requester === 'Incubator') return r.raisedbyincubator
          if (this.requester === 'Mentor') return r.raisedbymentor
          return false
        })
      }
      if (this.hideClosed) {
        requests = requests.filter(r => r.status !== 'closed' && r.assignee_status !== 'closed')
      }
      return requests
    },
  },
  methods: {
    addRequest(modalevent) {
      modalevent.preventDefault()
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation addRequest($title: String!, $description: String!, $priority: String!, $incubator_id: Int!, $requester_id: Int!, $is_request: Boolean!, $is_mentor: Boolean!, $raisedbymentor: Boolean! , $status: String!, $assignee_status: String!) {
            insert_requesttable_one(object: {
              title: $title,
              description: $description,
              priority: $priority,
              incubator_id: $incubator_id,
              requester_id: $requester_id,
              is_request: $is_request,
              is_mentor: $is_mentor,
              status: $status,
              assignee_status: $assignee_status,
              raisedbymentor:$raisedbymentor ,
            }) {
              requesttable_id
            }
          },
        `,
        variables: {
          title: this.request.title,
          description: this.request.description,
          priority: this.request.priority,
          incubator_id: this.selectedOrg.orgId,
          requester_id: this.selectedOrg.associationId,
          is_request: true,
          is_mentor: true,
          raisedbymentor: true,
          status: 'assigned',
          assignee_status: 'unchecked',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request added successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.request = {
            title: '',
            description: '',
            priority: '',
          }
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
          this.$bvModal.hide('addRequestModal')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding request',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssigneeStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssigneeStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Assignee status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request assignee status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`query requests($subdomain: String!, $incubatorId: Int!, $requesterId: Int!, $isRequest: Boolean!, $isMentor: Boolean!,$assigneeId: Int!) {
          requesttable(where: {_or: [
           { is_mentor: {_eq: $isMentor},
            requester_id: {_eq: $requesterId },
            is_request: { _eq: $isRequest },
            incubator_id:{_eq:$incubatorId } },
           {
            is_mentor: {_eq: $isMentor},
            assignee_id: {_eq: $assigneeId },
            is_request: {_eq: $isRequest},
            incubator_id: {_eq: $incubatorId}}
          }
          ],
               users_organizationtable: {domain: {_regex: $subdomain}},},
                order_by: {requesttable_id: desc}) {

            incubator_id
            is_request
            is_mentor
            is_partner
            requesttable_id
            priority
            raisedbymentor
            raisedbypartner
            raisedbyincubator
            created_at
            requester_id
            assignee_id
            assignee_status
            status
            title
            description
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      variables() {
        return {
          subdomain: this.getIncubatorFromSubdomain() || '.*',
          incubatorId: this.selectedOrg.orgId,
          requesterId: this.selectedOrg.associationId,
          assigneeId: this.selectedOrg.associationId,
          isRequest: true,
          isMentor: true,
        }
      },
      update: data => data.requesttable,
    },
  },
}
</script>

<style>
.selected-top-tab {
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.deselected-top-tab {
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>
